import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import cx from 'classnames';
import { Link } from 'gatsby-plugin-react-i18next';
import { ReactNode } from 'react';
import NewsRow from '../components/News/NewsRow';

export const renderOptions = (links: any): Options => {
  // create an asset map
  const assetMap = new Map();

  // loop through the assets and add them to the map
  for (const asset of links.assets.block) {
    assetMap.set(asset.sys.id, asset);
  }

  let entryOptions: any = {};

  if (links.entries) {
    // create an entry map
    const entryMap = new Map();

    if (links.entries.block) {
      for (const entry of links.entries.block) {
        entryMap.set(entry.sys.id, entry);
      }

      entryOptions[BLOCKS.EMBEDDED_ENTRY] = (node: any) => {
        // find the entry in the entryMap by ID
        const entry = entryMap.get(node.data.target.sys.id);

        if (entry.__typename === 'Contentful_News') {
          return <NewsRow news={entry} />;
        }
      };
    }

    if (links.entries.inline) {
      for (const entry of links.entries.inline) {
        entryMap.set(entry.sys.id, entry);
      }

      entryOptions[INLINES.EMBEDDED_ENTRY] = (node: any) => {
        // find the entry in the entryMap by ID
        const entry = entryMap.get(node.data.target.sys.id);

        if (entry.__typename === 'Contentful_News') {
          return (
            <Link to={`/news/${node.data.target.sys.id}`}>{entry.title}</Link>
          );
        }
      };
    }
  }

  return {
    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <span className="bold">{text}</span>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node: any) => {
        const localIdentifier = 'https://smartpay.co';
        const stylesClassName: string = cx.apply(
          null,
          node.content[0].marks.map((mark: { type: MARKS }) => mark.type)
        );

        if (node.data.uri.startsWith(localIdentifier)) {
          return (
            <Link
              to={node.data.uri.replace(localIdentifier, '')}
              className={stylesClassName}
            >
              {node.content[0].value}
            </Link>
          );
        }

        return (
          <a
            href={node.data.uri}
            target="_blank"
            rel="noopener noreferrer"
            className={stylesClassName}
          >
            {node.content[0].value}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        // find the asset in the assetMap by ID
        const asset = assetMap.get(node.data.target.sys.id);

        // render the asset accordingly
        return <img src={asset.url} alt={asset.title} />;
      },
      ...entryOptions,
    },
  };
};
