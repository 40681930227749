import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql } from 'gatsby';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import React from 'react';
import icon from '../assets/icons/icon-chevron-right-gray.svg';
import Layout from '../components/Layout/Layout';
import Asset from '../components/News/Asset';
import type { News, NewsCategory } from '../components/News/NewsRow';
import { toDateString } from '../utils';
import { renderOptions } from '../utils/contentful';
import styles from './NewsItem.module.scss';

export const query = graphql`
  query NewsItemQuery($id: String!, $language: String!, $dev: Boolean!) {
    contentful {
      news(id: $id, locale: $language, preview: $dev) {
        sys {
          id
        }
        excerpt(locale: $language)
        title(locale: $language)
        slug
        date
        actionButton
        actionButtonText
        actionButtonUrl
        actionButtonNewWindow
        cover {
          contentType
          url
          sys {
            id
          }
        }
        coverMobile {
          url
          width
          height
        }
        content {
          json
          links {
            assets {
              block {
                url
                width
                height
                title(locale: $language)
                contentType
                sys {
                  id
                }
              }
            }
            entries {
              block {
                sys {
                  id
                }
                __typename
                ... on Contentful_News {
                  title(locale: $language)
                  cover {
                    contentType
                    url
                    sys {
                      id
                    }
                  }
                  coverMobile {
                    url
                    width
                    height
                  }
                  slug
                  date
                  categoryCollection(locale: $language) {
                    items {
                      sys {
                        id
                      }
                      slug(locale: $language)
                      title(locale: $language)
                    }
                  }
                }
              }
            }
          }
        }
        categoryCollection(locale: $language) {
          items {
            sys {
              id
            }
            slug(locale: $language)
            title(locale: $language)
          }
        }
        merchantCollection(locale: $language) {
          items {
            title(locale: $language)
          }
        }
        publisherCollection(locale: $language) {
          items {
            title(locale: $language)
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const NewsItem = ({ data }: { data: { contentful: { news: News } } }) => {
  const { t, originalPath, i18n } = useI18next();
  const {
    contentful: {
      news: {
        content: { json: contentJSON, links },
        categoryCollection: { items: cats },
        publisherCollection: { items: publishers },
        merchantCollection: { items: merchants },
        title,
        date,
        slug,
        excerpt,
        actionButton,
        actionButtonText,
        actionButtonUrl,
        actionButtonNewWindow,
        cover,
        coverMobile,
      },
    },
  } = data;

  return (
    <Layout
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      className={styles.newsRoot}
      SEOProps={{
        title: `News | ${title}`,
        description: excerpt,
        ...(cover
          ? {
              ogImage: `${cover.url}?w=1200`,
              twitterImage: `${cover.url}?w=800`,
            }
          : {}),
      }}
    >
      <main className={styles.main}>
        <article className={styles.news}>
          <div className={styles.meta}>
            <nav className={styles.breads}>
              <Link to="/newsroom/" className={styles.btnBack}>
                {t('newsroom')}
              </Link>
              <img src={icon} width="20" height="20" className={styles.arrow} />
              {cats?.length > 0 && (
                <>
                  {cats.map((cat: NewsCategory, i: number) => (
                    <Link to={`/newsroom/#${cat.slug}`} className={styles.cat}>
                      {cat.title}
                    </Link>
                  ))}
                </>
              )}
            </nav>
            {cats?.length > 0 && (
              <nav className={styles.cats}>
                {cats.map((cat: NewsCategory, i: number) => (
                  <React.Fragment key={i}>
                    <span className={styles.cat}>{cat.title}</span>
                    {cat.slug === 'media' &&
                      publishers.length > 0 &&
                      publishers.map((publisher, i) => (
                        <span className={styles.extraInfo} key={i}>
                          {publisher.title}
                        </span>
                      ))}
                    {cat.slug === 'case-study' &&
                      merchants.length > 0 &&
                      merchants.map((merchant, i) => (
                        <span className={styles.extraInfo} key={i}>
                          {merchant.title}
                        </span>
                      ))}
                  </React.Fragment>
                ))}
              </nav>
            )}
            <h1>{title}</h1>
            <time className={styles.date}>{toDateString(date)}</time>
          </div>
          <Asset
            className={styles.cover}
            asset={cover}
            assetSquare={coverMobile}
            title={title}
            width={1800}
          />
          <div className={styles.content}>
            {documentToReactComponents(contentJSON, renderOptions(links))}

            {actionButton && actionButtonUrl && actionButtonText && (
              <div className={styles.actionsContainer}>
                <a
                  className={styles.action}
                  href={actionButtonUrl}
                  target={actionButtonNewWindow ? '_blank' : ''}
                >
                  {actionButtonText}
                </a>
              </div>
            )}
          </div>
        </article>
      </main>
    </Layout>
  );
};

export default NewsItem;
