import { Document } from '@contentful/rich-text-types';
import cx from 'classnames';
import { Link } from 'gatsby-plugin-react-i18next';
import { Fragment } from 'react';
import { Merchant } from '../../types';
import { toDateString } from '../../utils';
import styles from './NewsRow.module.scss';

export interface NewsCategory {
  sys: {
    id: string;
  };
  title: string;
  slug: string;
}

export interface Publisher {
  sys: {
    id: string;
  };
  title: string;
  slug: string;
}

export interface News {
  sys: {
    id: string;
  };
  content: {
    json: Document;
    links: any;
  };
  categoryCollection: {
    items: NewsCategory[];
  };
  publisherCollection: {
    items: Publisher[];
  };
  merchantCollection: {
    items: Merchant[];
  };
  slug: string;
  title: string;
  excerpt: string;
  date: string;
  cover: {
    url: string;
    width: number;
    height: number;
  };
  coverMobile: {
    url: string;
    width: number;
    height: number;
  };
  isHero: boolean;
  actionButton: boolean;
  actionButtonText: string;
  actionButtonUrl: string;
  actionButtonNewWindow: boolean;
}

export type NewsListByCategory = { [key: string]: News[] };

// https://www.notion.so/News-Cover-Image-Dimensions-1ead6924a46c4cd392a5de02f1e38e60
const COVER_BREAKPOINT_HERO_DESKTOP = 1004;
const COVER_BREAKPOINT_HERO_MOBILE = 500;
const COVER_BREAKPOINT_LIST_MOBILE = 314;

const COVER_BREAKPOINT_MOBILE = 768;

const NewsRow = ({
  news,
  isHero = false,
  className,
}: {
  news: News;
  isHero?: boolean;
  className?: string;
}) => {
  if (!news) {
    return null;
  }

  const coverLandscape = news.cover;
  const coverSquare = news.coverMobile;

  return (
    <Link
      key={news.sys.id}
      to={`/news/${news.slug}`}
      className={cx(styles.news, isHero ? styles.isHero : '', className)}
    >
      <article>
        {coverLandscape?.url && (
          <figure>
            <picture>
              {/* mobile square */}
              {coverSquare?.url && (
                <source
                  media={`(max-width: ${
                    isHero
                      ? COVER_BREAKPOINT_HERO_MOBILE
                      : COVER_BREAKPOINT_LIST_MOBILE
                  }px)`}
                  srcSet={coverSquare.url}
                />
              )}
              {/* mobile landscape */}
              <source
                media={`(max-width: ${COVER_BREAKPOINT_MOBILE}px)`}
                srcSet={coverLandscape.url}
              />
              {/* desktop square */}
              {news.coverMobile?.url && (
                <source
                  media={`(max-width: ${
                    isHero
                      ? COVER_BREAKPOINT_HERO_DESKTOP
                      : COVER_BREAKPOINT_MOBILE
                  }px)`}
                  srcSet={news.coverMobile.url}
                />
              )}
              {/* desktop landscape */}
              <img
                src={`${coverLandscape.url}`}
                alt={news.title}
                height={isHero ? 374 : 200}
                width={isHero ? 'auto' : 324}
              />
            </picture>
          </figure>
        )}
        <div className={styles.content}>
          {news.categoryCollection?.items && (
            <nav className={styles.cats}>
              {news.categoryCollection?.items.map((cat, i) => (
                <Fragment key={i}>
                  <span className={styles.cat}>{cat.title}</span>
                  {cat.slug === 'media' &&
                    news.publisherCollection?.items.length > 0 &&
                    news.publisherCollection.items.map((publisher, i) => (
                      <span className={styles.extraInfo} key={i}>
                        {publisher.title}
                      </span>
                    ))}
                  {cat.slug === 'case-study' &&
                    news.merchantCollection?.items.length > 0 &&
                    news.merchantCollection.items.map((merchant, i) => (
                      <span className={styles.extraInfo} key={i}>
                        {merchant.title}
                      </span>
                    ))}
                </Fragment>
              ))}
            </nav>
          )}
          <div className={styles.heading}>
            <h3>{news.title}</h3>
          </div>
          {isHero && news.excerpt && <p>{news.excerpt}</p>}
          <div className={styles.date}>
            <time>{toDateString(news.date)}</time>
          </div>
        </div>
      </article>
    </Link>
  );
};

export default NewsRow;
