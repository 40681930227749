import { isMobile } from 'react-device-detect';

// https://www.notion.so/News-Cover-Image-Dimensions-1ead6924a46c4cd392a5de02f1e38e60
const COVER_BREAKPOINT_SINGLE_MOBILE = 500;

const COVER_BREAKPOINT_MOBILE = 768;

const Asset = ({
  asset,
  assetSquare,
  title,
  width,
  className,
}: {
  asset: any;
  assetSquare?: any;
  title: string;
  width: number;
  className: string;
}) => {
  if (!asset?.url) {
    // TOFIX: use a formal placeholder
    return <div style={{ height: '1px' }} />;
  }

  const videoContentTypes = ['video/mp4', 'video/webm'];
  const isVideo = videoContentTypes.includes(asset.contentType);

  if (isVideo) {
    return (
      <video className={className} controls autoPlay={!isMobile} muted>
        <source src={asset.url} type={asset.contentType} />
        {/* TODO: add translation */}
        <span>Sorry, your browser doesn&lsquo;t support embedded videos.</span>
      </video>
    );
  }

  const coverLandscape = asset;
  const coverSquare = assetSquare;

  return (
    <picture>
      {/* mobile square */}
      {coverSquare?.url && (
        <source
          media={`(max-width: ${COVER_BREAKPOINT_SINGLE_MOBILE}px)`}
          srcSet={coverSquare.url}
        />
      )}
      {/* mobile landscape */}
      <source
        media={`(max-width: ${COVER_BREAKPOINT_MOBILE}px)`}
        srcSet={`${coverLandscape.url}?w=${width}`}
      />
      {/* desktop landscape */}
      <img className={className} src={`${coverLandscape.url}`} alt={title} />
    </picture>
  );
};

export default Asset;
